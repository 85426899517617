import { createAsyncThunk } from '@reduxjs/toolkit';
import EnvironmentService from 'services/environment.service';
import { handleResponseErrors } from "shared/networking/error_handling";

export const fetchSiteInformation = createAsyncThunk(
    'siteinformation/fetchSoftwareInformation',
    async (_) => {
        const response = await EnvironmentService.getSoftwareVersion();
        handleResponseErrors(response, "Software Informationen");
        return response;
    }
)