import React, { useEffect, useState } from "react";
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
    changeStep,
    resetGotoCreate,
    resetPreselect,
    resetPreselectNoAdditionError,
    resetPreselectSKUNotFoundError,
    resetState,
    resetStateOnSKUNotFoundError,
    resetStepChange,
    setIsNavigate,
    setIsSelectedByFilter,
    setReloadPath,
    setSkuAndNavigate
} from "./redux/productpreselection.slice";
import {
    getConfiguration,
    getPreselectionConfigurationOptions,
    gotoNext,
    preselectProductPostSkU,
} from "./redux/productpreselection.thunks";
import {
    ConfigurationStepperComponent
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {
    ConfigurationNavigationComponent
} from "configurations/shared/configuration_editor/components/configuration_navigation.component";
import { ConfigurationStepEnum } from "configurations/shared/configuration_editor/models/configuration_step.enum";
import { IStepChange } from "configurations/shared/configuration_editor/models/stepChange.model";
import { StepDescriptor } from "configurations/shared/configuration_editor/models/step_descriptor.model";
import { INavigationParams } from "configurations/shared/configuration_editor/models/navigation_params.model";
import { ContentContainer } from "shared/components/container/ContentContainer";
import {
    ProductInformation
} from "configurations/shared/configuration_editor/components/configuration_productinformation.component";
import IConfiguration from "../../../../../models/configurations/configuration";
import ErrorDialog from "../../../../../shared/components/error_dialog/error_dialog.component";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../../shared/components/loading_overlay/loading_overlay.component";
import { updateAvailableSteps, updateStepperState } from "../../redux/stepper.slice";
import { ErrorDialogButtons } from "shared/components/error_dialog/error_dialog.helper";
import { useApiError } from "shared/provider/api_error/api_error.provider";
import { PreselectionSKUorMtmNumberComponent } from "./preselectionSKUorMtmNumber.component";
import { FilterWrapperComponent } from "./filter/filterWrapper.component";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "shared/components/switchs/switch.component";
import { useNavigate } from "react-router-dom";
import { ConfigurationMode } from "configurations/shared/configurationMode.model";


interface ProductPreSelectionProps {
    configurationId: string,
    onNavigate: (next: StepDescriptor, params?: INavigationParams[]) => void,
    getCustomerData: (configuration: IConfiguration) => void,
    isConfigurationReadonly: boolean,
    configurationState: ConfigurationStepEnum
}

export const ProductPreSelectionComponent = (props: ProductPreSelectionProps) => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.configuration_productpreselection);
    const mode = useAppSelector((state) => state.configuration_mode).mode;
    const stepperState = useAppSelector((state) => state.configuration_stepper);

    const {t} = useTranslation();

    const [displayErrorDialog, setDisplayErrorDialog] = useState({ isVisible: false, errorMessage: "" });
    const [isLoading, setIsLoading] = useState(true);
    const [isPreselectionByFilter, setIsPreselectionByFilter] = useState(false);
    const { apiError, setApiError } = useApiError();

    const urlParams = new URLSearchParams(window.location.search);
    const sku = urlParams.get("sku");

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message ?? ""
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
        setApiError(null);
    };

    useEffect(() => {
        dispatch(updateStepperState(ConfigurationStepEnum.ProductPreSelection));
    }, []);

    useEffect(() => {
        if (state.loadedData.configuration !== null && !state.loadedData.configuration.sku && state.loadedData.configuration.id !== null && sku) {
            dispatch(setSkuAndNavigate(sku));
        }
    }, [state.loadedData.configuration, sku]);

    useEffect(() => {
        if (state.command.preselectPostSKU.goNextAutomatically) {
            dispatch(preselectProductPostSkU());
        }
    }, [state.command.preselectPostSKU.goNextAutomatically]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if(state.actualData.reloadPath) {
            navigate(state.actualData.reloadPath);
        }
    }, [state.actualData.reloadPath]);

    useEffect(() => {
        if (!state.loadedData.isNavigating && props.configurationId) {
            dispatch(getConfiguration(props.configurationId));
        }
    }, [props.configurationId]);

    useEffect(() => {
        if (state.loadedData?.configuration) {
            props.getCustomerData(state.loadedData.configuration);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [state.loadedData]);

    useEffect(() => {
        if (state.loadedData.configuration?.sku && state.loadedData.configuration?.id !== null) {
            dispatch(getPreselectionConfigurationOptions(state.loadedData.configuration.id));
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.loadedData.configuration !== null) {
            if (state.loadedData.configuration.productionType !== null && state.loadedData.configuration.productionType !== undefined) {
                dispatch(updateAvailableSteps(state.loadedData.configuration.productionType));
            }
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        } else {
            hideError();
        }
    }, [state.query.get.status]);

    useEffect(() => {
        if (state.command.gotoCreate.status === "success") {
            const target = stepperState.nextStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(resetGotoCreate());
        }
    }, [state.command.gotoCreate.status]);


    useEffect(() => {
        if (state.command.preselect.status === "success") {
            const target = stepperState.nextStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(resetPreselect());
        }
        if (state.command.preselect.status === "error") {
            if (state.error.preselectNoAdditionError) {
                showError(state.error.errormessage);
                dispatch(resetPreselectNoAdditionError());
            } else {
                showError(state.command.preselect.message);
            }
            dispatch(resetPreselect());
        }
    }, [state.command.preselect.status]);


    useEffect(() => {
        if (state.query.post.status === "error") {
            showError(state.query.post.message);
            if (state.error.preselectSKUNotFoundError) {
                dispatch(resetStateOnSKUNotFoundError());
            }
        } else {
            dispatch(resetPreselectSKUNotFoundError());
        }
    }, [state.query.post.status]);

    useEffect(() => {
        if (state.actualData.handleStepChange === true) {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(updateStepperState(target.value));
            dispatch(resetStepChange());
        }
    }, [state.actualData.handleStepChange]);

    if (state.actualData.navigationTarget) {
        navigate(state.actualData.navigationTarget);
    }

    useEffect(() => {
        if (state.actualData.importedConfiguration?.orderConfigurationId) {
            if (state.actualData.importedConfiguration.messages?.length) {
                showError(state.actualData.importedConfiguration.messages.map(mes => mes.message).join("\n"));
                return;
            }
            reloadConfiguration(mode, state.actualData.importedConfiguration.orderConfigurationId);
        }
    }, [mode, state.actualData.importedConfiguration]);

    useEffect(() => {
        switch (state.command.cloneMtmNumber.status)
        {
            case "pending":
                setIsLoading(true);
                break;

            case "error":
            case "success":
                setIsLoading(false);
                break;
        }
    }, [state.command.cloneMtmNumber.status]);

    if (displayErrorDialog.isVisible) {
        return (<ErrorDialog
            message={displayErrorDialog.errorMessage}
            apiError={apiError}
            buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                if (state.actualData.importedConfiguration) {
                    let url: string;
                    if (mode == ConfigurationMode.OrderConfiguration) {
                        url = `/orderconfigurations/orderconfiguration/create?orderconfigurationid=${state.actualData.importedConfiguration.orderConfigurationId}`;
                    }  
                    else {
                        url = `/quotationconfigurations/quotationconfiguration/create?orderconfigurationid=${state.actualData.importedConfiguration.orderConfigurationId}`;
                    }
                    dispatch(setReloadPath(url));
                }
                else {
                    hideError();
                }
            })}
        />);
    }

    const reloadConfiguration = (mode: ConfigurationMode, orderConfigurationId: string) : void => {
        let path = "";
    
        if (mode == ConfigurationMode.OrderConfiguration) {
            path = `/orderconfigurations/orderconfiguration/create?orderconfigurationid=${orderConfigurationId}`;
        }  
        else {
            path = `/quotationconfigurations/quotationconfiguration/create?orderconfigurationid=${orderConfigurationId}`;
        }
        navigate(path);  
    };

    return (
        <LoadingOverlayContainer>
            {isLoading ? (
                <LoadingOverlayComponent />
            ) : (
                <>
                    {
                        (state.loadedData.configuration?.sku !== null) &&
                        <ProductInformation
                            sku={state.loadedData.configuration.sku}
                            skuLongIdentifier={state.loadedData.configuration.productName} />
                    }

                    <ConfigurationStepperComponent
                        onStepChangeExecute={(step: IStepChange) => dispatch(changeStep(step))}
                        orderConfigurationStateStep={props.configurationState} />

                    <ContentContainer>

                        <SwitchComponent
                            leftText={t("Preselection.SelectBySKU")}
                            rightText={t("Preselection.SelectByFilter")}
                            disabled={props.isConfigurationReadonly}
                            checked={isPreselectionByFilter}
                            onChange={(event, isPreselectionByFilter) => {
                                setIsPreselectionByFilter(isPreselectionByFilter);
                                dispatch(setIsSelectedByFilter(isPreselectionByFilter));
                            }} />

                        <Grid container>
                            {!isPreselectionByFilter && <PreselectionSKUorMtmNumberComponent disabled={props.isConfigurationReadonly} />}
                            {isPreselectionByFilter && <FilterWrapperComponent disabled={props.isConfigurationReadonly} />}
                        </Grid>

                        <ConfigurationNavigationComponent
                            canNext={state.command.preselect.canExecute}
                            next={() => dispatch(gotoNext())} />
                    </ContentContainer>
                </>
            )}
        </LoadingOverlayContainer>
    );
};
