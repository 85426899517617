import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import React, { useEffect } from "react";
import {Container} from "reactstrap";
import {getConfig} from "services/config.service";
import {FlexBox, Left, Right} from "shared/shared";
import colors from "shared/style/colors";
import styled from "styled-components";
import { fetchSiteInformation } from "./redux/site_information.thunks";
import { resetState } from "./redux/site_information.slice";

const FooterArea = styled.div`
  background-color: ${colors.mediLightGray};
  flex-shrink: 0;
`;

const Slogan = styled.div`
  color: ${colors.mediMagenta};
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentBadge = styled.span`
  color: ${colors.mediGray};
`;

const SwaggerLink = styled.a`
  color: ${colors.mediGray};
  margin-right: 10px;

  :hover,
  :focus,
  :active,
  :visited {
    text-decoration: none;
    color: ${colors.mediMagenta};
  }
;
  color: ${colors.mediMagenta};
`;

export const FooterComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.site_information);
    const config = getConfig();

    useEffect(() => {
      dispatch(fetchSiteInformation());
      return () => {
        dispatch(resetState());
      }
    }, [dispatch]);

    return (
        <FooterArea>
            <Container>
                <FlexBox>
                    <Left>
                        <Slogan>medi. ich fühl mich besser.</Slogan>
                    </Left>
                    <Right>
                        {config.showSwaggerLinks ? <>
                            <SwaggerLink href="/swagger-private" target="_blank">Private API</SwaggerLink>{" "}
                            <SwaggerLink href="/swagger-public" target="_blank">Public API</SwaggerLink>
                        </> : <></>}
                        <span><EnvironmentBadge>{`${state.loadedData.softwareVersion}-${state.loadedData.environmentName}`}</EnvironmentBadge></span>
                    </Right>
                </FlexBox>
            </Container>
        </FooterArea>
    );
};
