import {getConfig} from "./config.service";
import {Configuration, PublicClientApplication} from "@azure/msal-browser";
import HttpService from "shared/networking/http_service";

const appConfig = getConfig();

const config: Configuration = {
    auth: {
        authority: `https://login.microsoftonline.com/${appConfig.authTenantId}`,
        clientId: appConfig.authClientAppId,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

const authenticationMTMParameters = {
    scopes: [
        "openid",
        "profile",
        `api://${appConfig.mtmService.authServerAppId}/access_as_user`,
    ],
};

const publicClientMTMApplication = await PublicClientApplication.createPublicClientApplication(config);

class MTMAuthorisationServiceAPI extends HttpService {

    public async getAccessTokenMTMHeader(): Promise<string> {
        const accessToken = await this.getAccessTokenMTMViaMSAL();
        return `Bearer ${accessToken}`;
    }

    private async getAccessTokenMTMViaMSAL(): Promise<string> {
        const accounts = publicClientMTMApplication.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                scopes: authenticationMTMParameters.scopes,
                account: accounts[0]
            };
            const accessToken = await publicClientMTMApplication.acquireTokenSilent(request).then((response) => {
                return response.accessToken;
            }).catch((error) => {
                // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
                return null;
            });

            return accessToken;
        }

        return null;
    }

}

const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const mTMAuthorisationService =   new MTMAuthorisationServiceAPI(TimeoutMilliseconds);

