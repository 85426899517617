import {configureStore} from "@reduxjs/toolkit";
import configurationCreateSlice from "configurations/shared/configuration_editor/steps/create/redux/create.slice";
import configurationMeasurementsSlice
    from "configurations/shared/configuration_editor/steps/measurements/redux/measurements.slice";
import configurationProductPreSelectionSlice
    from "configurations/shared/configuration_editor/steps/productpreselection/redux/productpreselection.slice";
import configurationAdditionsSlice
    from "configurations/shared/configuration_editor/steps/additions/redux/additions.slice";
import configurationProductInstructionsSlice
    from "configurations/shared/configuration_editor/steps/productinstructions/redux/productinstructions.slice";
import configurationCompletionSlice
    from "configurations/shared/configuration_editor/steps/completion/redux/completion.slice";
import editorSlice from "configurations/shared/configuration_editor/redux/editor.slice";
import configurationCustomerDataSlice from "../configurations/shared/configuration_editor/redux/customerdata.slice";
import configurationOverviewSlice
    from "../configurations/shared/configuration_overview/redux/configurations_overview.slice";
import configurationProductionOrderSlice
    from "../configurations/shared/configuration_productionorder/redux/configurations_productionorder.slice";
import configurationCommentsSlice
    from "../configurations/shared/configuration_comments/redux/configurations_comments.slice";
import configurationmodeSlice from "../configurations/shared/configurationmodeSlice";
import stepperSlice from "../configurations/shared/configuration_editor/redux/stepper.slice";
import filterSlice
    from "../configurations/shared/configuration_editor/steps/productpreselection/filter/redux/filter.slice";
import salesitemdataSlice from "configurations/shared/configuration_editor/redux/salesitemdata.slice";
import configurationEventsSlice from "../configurations/shared/configuration_events/redux/configurations_events.slice";
import actionsmenuSlice from "../configurations/shared/configuration_editor/redux/actionsmenu.slice";
import site_informationSlice from "../shared/components/page_layout/redux/site_information.slice";

export const reduxStore = configureStore({
    reducer: {
        filter: filterSlice,
        configuration_stepper: stepperSlice,
        configuration_mode: configurationmodeSlice,
        configuration_comments: configurationCommentsSlice,
        configuration_customerData: configurationCustomerDataSlice,
        configuration_editor: editorSlice,
        configuration_create: configurationCreateSlice,
        configuration_productpreselection: configurationProductPreSelectionSlice,
        configuration_measurements: configurationMeasurementsSlice,
        configuration_additions: configurationAdditionsSlice,
        configuration_productinstructions: configurationProductInstructionsSlice,
        configuration_completion: configurationCompletionSlice,
        configuration_overview: configurationOverviewSlice,
        configuration_productionorder: configurationProductionOrderSlice,
        configuration_salesitemdata: salesitemdataSlice,
        configuration_events: configurationEventsSlice,
        configuration_actionsmenu: actionsmenuSlice,
        site_information: site_informationSlice,
    }
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
