import {ConfigurationMeasurementsState} from "./measurements.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";
import Enumerable from "linq";
import {ConfigurationMode} from "../../../../configurationMode.model";
import { ComponentDataEvent, EvaluationResultEnum, QualityResultResponse } from "@medi-mtm/components";
import { ConfigurationState } from "models/configuration_state.enum";
import { MassDefinitionEvaluationsResponse } from "../models/responses";

export const validateState = (state: ConfigurationMeasurementsState) => {
    // validate input data
    return (doesExist(state.loadedData.configuration)
        && hasValue(state.actualData.productConfigurationId)
        && state.actualData.productConfigurationRunId != null
        && !state.actualData.hasValidChecks
        && !state.actualData.hasValidComponent);
};

export const isRestoreCycle = (state: ConfigurationMeasurementsState): boolean => {
    return (
        state.loadedData.configuration.hasMeasurements &&
        hasValue(state.loadedData.configuration.productConfigurationId) &&
        hasValue(state.loadedData.configuration.productConfigurationRunId) &&
        state.loadedData.configuration.productConfigurationRunId > 0);
};

export const mustCallApi = (state: ConfigurationMeasurementsState, configurationMode: ConfigurationMode): boolean => {
    return (
        state.loadedData.configuration?.productConfigurationId !== state.actualData.productConfigurationId 
        || state.loadedData.configuration?.productConfigurationRunId !== state.actualData.productConfigurationRunId 
        ||  ( configurationMode === ConfigurationMode.QuotationConfiguration && !state.actualData.hasMeasurement)
        || state.loadedData.stepState.currentStep < ConfigurationState.InProgressMeasured
    );
};

export const canGotoNext = (state: ConfigurationMeasurementsState): boolean => {
    // prüfen ob erster teil vor && paßt
    return (state.actualData.hasValidComponent && (state.command.evaluateMeasurements.canExecute || state.actualData.isOnlyWarning));
};

export const updateComponentData = (state: ConfigurationMeasurementsState, data: ComponentDataEvent) => {
    state.actualData.hasValidComponent = data.isValid;
    state.actualData.productConfigurationId = data.productConfigurationId;
    state.actualData.productConfigurationRunId = data.productConfigurationRunId;
};

export const calculateEvaluateCanExecute = (state: ConfigurationMeasurementsState): boolean => {
    return state.actualData.hasValidComponent;
};

export const handleEvaluation = (state: ConfigurationMeasurementsState, qualityResultResponse: QualityResultResponse) => {
    if(qualityResultResponse) {
        const valideChecks = Enumerable.from(qualityResultResponse.massPointEvaluationResult.massDefinitionEvaluations)
            .all(mde => Enumerable.from(mde.massPoints)
                .all(tr => tr.evaluationResult === EvaluationResultEnum.Success));
        state.actualData.hasValidChecks = valideChecks;

        if (!valideChecks && qualityResultResponse.massPointEvaluationResult.massDefinitionEvaluations.length > 0) {
            state.actualData.errorMessages =
                Enumerable.from(qualityResultResponse.massPointEvaluationResult.massDefinitionEvaluations)
                    .selectMany(mde => mde.massPoints)
                    .where(tr => tr.evaluationResult >= EvaluationResultEnum.Warning && tr.evaluationResult <= EvaluationResultEnum.Error)
                    .selectMany(mpt => mpt.massPointTestRuns).where(mpt => mpt.evaluationResult.result >= EvaluationResultEnum.Warning && mpt.evaluationResult.result <= EvaluationResultEnum.Error)
                    .select(res => ({errorMessage: res.evaluationResult.message, severity: res.evaluationResult.result}))
                    .where(obj => obj.errorMessage !== "")
                    .toArray().sort((a, b) => b.severity - a.severity);
        }

        state.actualData.isOnlyWarning = Enumerable.from(qualityResultResponse.massPointEvaluationResult.massDefinitionEvaluations)
            .all(mde => Enumerable.from(mde.massPoints)
                .all(tr => tr.evaluationResult <= EvaluationResultEnum.Warning));
    }
};
