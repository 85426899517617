import { TFunction } from "i18next";
import { EnumDescriptor } from "./enum_descriptor";

export enum ConfigurationState {
    New,

    InProgress,

    InProgressProductPreselected,

    InProgressMeasured,

    InProgressProductLineSelected,

    InProgressProductSelected,

    InProgressAdditionsSelected,

    InProgressProductionInstructionsSelected,

    Completed,

    Finalized
}

export const addConfigurationStateTexts = (t: TFunction<"translation", undefined> ) => {
    ConfigurationStateValues.forEach(cs => {
        const enumValue = ConfigurationState[cs.value];
        const value = t(`OrderConfigurationState_${enumValue}`);
        cs.text = value;
    });
}

export const ConfigurationStateValues: Array<EnumDescriptor<ConfigurationState>> = [
    { value: ConfigurationState.Finalized, text: "" },
    { value: ConfigurationState.Completed, text: "" },
    { value: ConfigurationState.InProgressProductionInstructionsSelected, text: "" },
    { value: ConfigurationState.InProgressAdditionsSelected, text: "" },
    { value: ConfigurationState.InProgressMeasured, text: "" },
    { value: ConfigurationState.InProgressAdditionsSelected, text: "" },
    { value: ConfigurationState.New, text: "" },
];
