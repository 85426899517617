import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import * as React from "react";
import {IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import i18next, {t} from "i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {GridColumnHeaderParams} from "@mui/x-data-grid-pro";
import {formatDateTime} from "../../../../../shared/date/formatting";
import { ConfigurationState, ConfigurationStateValues } from "models/configuration_state.enum";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";
import Configurations_overviewService from "services/configurations_overview_service";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);

const renderMenu = (params: GridCellParams) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cloneId, setCloneId] = React.useState(null);

    const cloneConfiguration = async () => {
        const response = await Configurations_overviewService.cloneQuotationConfiguration(params.row.id);
        setCloneId(response.getData());
    };
    
    const navigate = useNavigate();
    const handleIconButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuButtonClick = (subPath: string) => {
        const id = params.row.id;
        const url = `${subPath}?orderconfigurationid=${id}`;
        navigate(url);
    };

    if (cloneId)
    {
        navigate(`/quotationconfigurations/quotationconfiguration/create?orderconfigurationid=${cloneId}`);
    }

    return <>
        <IconButton
            aria-controls={`actions-menu-${params.row.id}`}
            aria-haspopup="true"
            onClick={handleIconButtonClick}
            size="large">
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id={`actions-menu-${params.row.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={() => handleMenuButtonClick("/quotationconfigurations/quotationconfiguration/create")}>
                <>{t("OverviewRestoreConfigurationButtonLabel")}</>
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuButtonClick("/quotationconfigurations/quotationconfiguration/productionorder")}>
                <>{t("OverviewDisplayJsonButtonLabel")}</>
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuButtonClick("/quotationconfigurations/quotationconfiguration/events")}>
                <>{t("OverviewEventsButtonLabel")}</>
            </MenuItem>
            <MenuItem
                onClick={() => cloneConfiguration()}>
                <>{t("OverviewCloneButtonLabel")}</>
            </MenuItem>
        </Menu>
    </>;
};

const renderErpItemIdAndPosition = (params: GridCellParams) => {
    const erpItemId = params.row.erpItemId;
    const erpItemPosition = params.row.erpItemPosition;
    return <span>{`${erpItemId}/${erpItemPosition}`}</span>;
};

const renderDateTimeLocalized = (params: GridCellParams) => {
    return <span>{formatDateTime(params.row.createdAt, i18next.language)}</span>;
};

const renderStates = (params: GridCellParams) => {
    const {t} = useTranslation();
    const state = ConfigurationState[params.row.state];
    const readonly = params.row.readonly ? <Tooltip title={t("IsReadonly")}><LockIcon style={{ verticalAlign: "middle", fontSize: "inherit", marginLeft: "0.25em" }} /></Tooltip> : <></>;
    return <span>{t(`OrderConfigurationState_${state}`)}{readonly}</span>;
};

const getStates = () => {
    return ConfigurationStateValues.map(cs => cs.text);
};

const renderColumnHeader = (params: GridColumnHeaderParams) => (
    <strong>
        <>
            {t(params.colDef.headerName)}
        </>
    </strong>
);

export const quotationconfiguration_overviewColumns: GridColDef[] = [
    {
        field: "",
        width: 70,
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: renderMenu
    },
    {
        field: "id",
        headerName: "OverviewQuotationIdLabel",
        renderHeader: renderColumnHeader,
        width: 280,
        sortable: false,
        hideable: false,
        pinnable: false,
    },
    {
        field: "erpTenant",
        headerName: "COMMON:ErpTenant",
        renderHeader: renderColumnHeader,
        sortable: false,
        hideable: false,
        pinnable: false
    },
    {
        field: "erpGroupCompanyNumber",
        headerName: "COMMON:GroupCompany",
        renderHeader: renderColumnHeader,
        width: 40,
        sortable: false,
        hideable: false,
        pinnable: false,
    },
    {
        field: "erpItemIdAndPosition",
        headerName: "OverviewQuotationNumberLabel",
        renderHeader: renderColumnHeader,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: renderErpItemIdAndPosition
    },
    {
        field: "createdBy",
        headerName: "OverviewCreatedByLabel",
        renderHeader: renderColumnHeader,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
    },
    {
        field: "createdAt",
        headerName: "OverviewCreatedAtLabel",
        renderHeader: renderColumnHeader,
        width: 140,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: renderDateTimeLocalized
    },
    {
        field: "state",
        headerName: "OverviewStateLabel",
        renderHeader: renderColumnHeader,
        minWidth: 140,
        sortable: false,
        hideable: true,
        pinnable: false,
        type: "singleSelect",
        valueOptions: getStates,
        renderCell: renderStates
    },
    {
        field: "orsBuildVersion",
        headerName: "Version",
        renderHeader: renderColumnHeader,
        sortable: false,
        hideable: true,
        pinnable: false,
    }
];
