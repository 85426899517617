import React from "react";
import {HeaderComponent} from "./header.component";
import {HideOnExclusiveMode} from "sales/hideonexclusivemode.component";
import {MenuComponent} from "./menu.component";
import {ContentComponent} from "./content.component";
import {ErrorHandlerComponent} from "../error_handling/error_handler.component";
import AppRouterComponent from "shared/routing/approuter.component";
import {FooterComponent} from "./footer.component";
import { useTranslation } from "react-i18next";
import { addConfigurationStateTexts } from "models/configuration_state.enum";

export const LayoutArea = () => {
    const {t} = useTranslation();
    addConfigurationStateTexts(t);

    return (
        <div className="layoutArea">
            <HeaderComponent/>
            <HideOnExclusiveMode>
                <MenuComponent/>
            </HideOnExclusiveMode>
            <ContentComponent>
                <ErrorHandlerComponent>
                    <AppRouterComponent/>
                </ErrorHandlerComponent>
            </ContentComponent>
            <FooterComponent/>
        </div>
    );
};
