import {useTranslation} from "react-i18next";
import {useReadonly} from "shared/provider/orderconfiguration/orderconfiguration.provider";
import LockIcon from "@mui/icons-material/Lock";
import {Tooltip} from "@mui/material";

const ReadonlyInfoComponent = () => {

    const {t} = useTranslation();
    const {isReadonly} = useReadonly();

     return (
        isReadonly ? <Tooltip title={t("IsReadonly")}><LockIcon style={{ marginRight: "5px", marginBottom: "4px" }} /></Tooltip> : <></>
    );
};

export default ReadonlyInfoComponent;
